import { ChevronRight, MenuOpen, CallMade } from '@mui/icons-material';
import {
    Button,
    Divider,
    Drawer,
    DrawerProps,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    SvgIcon,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { flatMapDeep, isEmpty, isNil, ListIterator } from 'lodash';
import React, { Fragment, memo, PropsWithChildren, useMemo, useState } from 'react';
import { useQuery, useNavigate } from '../../../hooks';
import { IMenuItem } from '../../../reducers/types';
import { useAppDispatch, useAppSelector } from '../../../store';
import { setMainMenu } from '../../../store/slices';
import { AppTheme } from '../../app';
import { Tooltip } from '../../common';
import {
    WkAccruals,
    WkAdministration,
    WkBudgets,
    WkContracts,
    WkDataExchange,
    WkHome,
    WkInvoices,
    WkLegalHolds,
    WkMatters,
    WkOutsideCounsel,
    WkReports,
    WkSystemAdmin,
    WkWorkToDo,
    WkDoubleArrowRight,
    WkLegalEngagement,
    WkChipIcon,
} from '../../icons';
import { PaneMenu } from '../paneMenu';

// eslint-disable-next-line max-lines-per-function
const useStyles = makeStyles<AppTheme>((theme) => {
    const menuItemSelected = {
        '&, &:hover, &:focus': {
            backgroundColor: theme.palette.background.secondary,
            color: theme.palette.primary.main,
        },
    };

    return {
        drawer: {
            flexShrink: 0,
            whiteSpace: 'nowrap',
            color: theme.palette.common.white,
        },
        drawerExpanded: {
            width: theme.leftMenu.expandedWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerCollapsed: {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            width: theme.leftMenu.collapsedWidth,
        },
        paneMenu: {
            width: theme.leftMenu.paneWidth,
            backgroundColor: theme.palette.background.secondary,
            color: theme.palette.primary.dark,
        },
        menuBar: {
            position: 'relative',
            backgroundColor: theme.palette.view.main,
            color: 'inherit',
            border: 0,
            borderTop: `1px solid ${theme.palette.grey[300]}`,
            overflowX: 'hidden',
            overflow: 'overlay',
            ...theme.styles.scrollbar.primary,
        },
        menuButton: {
            fontSize: theme.typography.subtitle2.fontSize,
            minWidth: 'auto',
            color: 'inherit',
            flexDirection: 'column',
            lineHeight: 1,
            '&>*': {
                marginTop: 3,
            },
        },
        menuButtonIcon: {
            transform: 'rotate(0deg)',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        menuButtonIconExpanded: {
            transform: 'rotate(180deg)',
        },

        toolbar: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1.25),
            minHeight: 45,
            minWidth: theme.leftMenu.collapsedWidth,
        },
        menu: {
            padding: theme.spacing(1.25, 0),
        },
        menuItem: {
            color: 'inherit',
            padding: theme.spacing(1, 2),
            '&:hover': {
                backgroundColor: theme.palette.view.light,
            },
        },
        menuItemExpanded: {
            minWidth: theme.leftMenu.expandedWidth,
        },
        menuItemSelected,
        menuItemActive: {
            ...menuItemSelected,
            border: `3px solid ${theme.palette.success.light}`,
            borderRight: 0,
        },
        menuItemIcon: {
            color: 'inherit',
            minWidth: 0,
        },
        divider: {
            margin: theme.spacing(1.25, 2),
            borderColor: theme.palette.grey[200],

            '&:last-child': {
                display: 'none',
            },
        },
        menuDivider: {
            margin: theme.spacing(0, 2),
            borderColor: theme.palette.grey[200],
        },

        menuItemText: {
            marginLeft: theme.spacing(3),
        },

        tooltipPlacementLeftRight: {
            marginLeft: theme.spacing(3.5),
        },

        tooltipPlacementTop: {
            margin: theme.spacing(1.5, 0),
        },
    };
});

export enum MainMenuItem {
    Home = 'home',
    WorkToDo = 'work to do',
    Matters = 'matters',
    Reports = 'dynamic reports',
    Analytics = 'analytics',
    Vendors = 'vendors',
    Budgets = 'budgets',
    Accruals = 'accruals',
    Invoices = 'invoices',
    Contracts = 'contracts',
    LegalHolds = 'legal holds',
    DataExchange = 'data exchange',
    Administration = 'administration',
    SystemAdmin = 'system administration',
    LegalEngagement = 'legal engagement',
    Others = 'others',
}

const mainMenuIcon = new Map<MainMenuItem, ReturnType<typeof SvgIcon>>([
    [MainMenuItem.Home, <WkHome key={MainMenuItem.Home} />],
    [MainMenuItem.WorkToDo, <WkWorkToDo key={MainMenuItem.WorkToDo} />],
    [MainMenuItem.Matters, <WkMatters key={MainMenuItem.Matters} />],
    [MainMenuItem.Reports, <WkReports key={MainMenuItem.Reports} />],
    [MainMenuItem.Analytics, <WkReports key={MainMenuItem.Analytics} />],
    [MainMenuItem.Vendors, <WkOutsideCounsel key={MainMenuItem.Vendors} />],
    [MainMenuItem.Budgets, <WkBudgets key={MainMenuItem.Budgets} />],
    [MainMenuItem.Accruals, <WkAccruals key={MainMenuItem.Accruals} />],
    [MainMenuItem.Invoices, <WkInvoices key={MainMenuItem.Invoices} />],
    [MainMenuItem.Contracts, <WkContracts key={MainMenuItem.Contracts} />],
    [MainMenuItem.LegalHolds, <WkLegalHolds key={MainMenuItem.LegalHolds} />],
    [MainMenuItem.Administration, <WkAdministration key={MainMenuItem.Administration} />],
    [MainMenuItem.SystemAdmin, <WkSystemAdmin key={MainMenuItem.SystemAdmin} />],
    [MainMenuItem.DataExchange, <WkDataExchange key={MainMenuItem.DataExchange} />],
    [MainMenuItem.LegalEngagement, <WkLegalEngagement key={MainMenuItem.LegalEngagement} />],
    [MainMenuItem.Others, <MenuOpen viewBox="0 2 22 22" key={MainMenuItem.Others} />],
]);

type MainMenuOptions = PropsWithChildren<{
    classes?: DrawerProps['classes'];
}>;

// eslint-disable-next-line max-lines-per-function
export const MainMenu = memo(function MainMenuComponent(props: MainMenuOptions) {
    const classes = useStyles();
    const { classes: externalClasses = {} } = props;
    const [submenu, setSubMenu] = useState<IMenuItem>();
    const [subMenuExpanded, setSubMenuExpanded] = useState<boolean>();
    const megaMenu = useAppSelector((state) => state.app.megaMenu);
    const { mainMenuExpanded } = useAppSelector((state) => state.ui.persistState);
    const dispatch = useAppDispatch();
    const { navigate } = useNavigate();
    const { screenId } = useQuery<{ screenId: string }>();

    const activeMainMenuItem = useMemo(() => {
        const iteratee: ListIterator<IMenuItem, IMenuItem[]> = (item: IMenuItem) =>
            (item.children?.length && flatMapDeep(item.children, iteratee)) || [item];

        return megaMenu?.find((paneMenuItem) => {
            const items = flatMapDeep([paneMenuItem], iteratee);
            return Boolean(items.find(({ id }) => id === Number(screenId)));
        });
    }, [megaMenu, screenId]);

    const handleMenuSelection = (menuItem: IMenuItem) => (): void => {
        const { children } = menuItem;

        const hasChildren = !isNil(children) && !isEmpty(children);
        if (hasChildren) {
            setSubMenu(menuItem);
            setSubMenuExpanded(true);
            return;
        }
        navigate({ menuItem, options: { clearBreadcrumbs: true } });
        setSubMenuExpanded(false);
    };

    const toggleDrawer = (): void => {
        dispatch(setMainMenu({ expanded: !mainMenuExpanded }));
    };

    return (
        <>
            <Drawer
                variant="permanent"
                data-testid={mainMenuExpanded ? 'main-menu-expanded' : 'main-menu-collapsed'}
                classes={{
                    ...externalClasses,
                    root: classNames(classes.drawer, externalClasses.root, {
                        [classes.drawerExpanded]: mainMenuExpanded,
                        [classes.drawerCollapsed]: !mainMenuExpanded,
                    }),
                    paper: classNames(classes.menuBar, externalClasses.paper, {
                        [classes.drawerExpanded]: mainMenuExpanded,
                        [classes.drawerCollapsed]: !mainMenuExpanded,
                    }),
                }}>
                <div className={classNames(classes.toolbar, { [classes.menuItemExpanded]: mainMenuExpanded })}>
                    <Tooltip
                        title={mainMenuExpanded ? 'Collapse Menu' : 'Expand Menu'}
                        placement={mainMenuExpanded ? 'left' : 'right'}>
                        <Button
                            onClick={toggleDrawer}
                            color={'primary'}
                            variant={'text'}
                            disableFocusRipple
                            data-testid={mainMenuExpanded ? 'collapse-menu' : 'expand-menu'}
                            classes={{
                                root: classes.menuButton,
                            }}>
                            <WkDoubleArrowRight
                                className={classNames(classes.menuButtonIcon, {
                                    [classes.menuButtonIconExpanded]: mainMenuExpanded,
                                })}
                            />
                        </Button>
                    </Tooltip>
                </div>
                <Divider data-testid="main-menu-divider" classes={{ root: classes.menuDivider }} />

                <List classes={{ root: classes.menu }}>
                    {megaMenu?.map((menuItem, index) => {
                        const { name = '', id, displayValue, children, isDivider, isNewWindow } = menuItem;
                        const menuItemType = name.toLowerCase() as MainMenuItem;
                        const title = displayValue || name;
                        const isActive = activeMainMenuItem === menuItem;
                        const isSelectedItem =
                            (subMenuExpanded && submenu === menuItem) || (!subMenuExpanded && isActive);
                        const menuIconTooltip =
                            (mainMenuExpanded && title) ||
                            (children?.length && `View ${title} Submenu`) ||
                            (isNewWindow && `View ${title} (Opens in a new tab)`) ||
                            title;

                        return (
                            <Fragment key={id || index}>
                                {title && (
                                    <ListItem
                                        data-testid="main-menu-item"
                                        button
                                        key={id || index}
                                        classes={{
                                            root: classNames(classes.menuItem, {
                                                [classes.menuItemActive]: isActive,
                                                [classes.menuItemSelected]: isSelectedItem,
                                                [classes.menuItemExpanded]: mainMenuExpanded,
                                            }),
                                        }}
                                        onClick={handleMenuSelection(menuItem)}>
                                        <Tooltip
                                            title={menuIconTooltip}
                                            placement={mainMenuExpanded ? 'top' : 'right'}
                                            classes={{
                                                tooltipPlacementRight: classes.tooltipPlacementLeftRight,
                                                tooltipPlacementTop: classes.tooltipPlacementTop,
                                            }}>
                                            <ListItemIcon classes={{ root: classes.menuItemIcon }}>
                                                {mainMenuIcon.get(menuItemType) ||
                                                    mainMenuIcon.get(MainMenuItem.Others)}
                                            </ListItemIcon>
                                        </Tooltip>
                                        <ListItemText primary={title} classes={{ root: classes.menuItemText }} />
                                        {mainMenuIcon.get(menuItemType) ===
                                            mainMenuIcon.get(MainMenuItem.Analytics) && (
                                            <WkChipIcon sx={{ width: '44px', height: '17px', marginRight: '60px' }} />
                                        )}
                                        {Boolean(children?.length) && (
                                            <Tooltip title={`View ${title} Submenu`} placement="right">
                                                <ChevronRight />
                                            </Tooltip>
                                        )}
                                        {!children?.length && isNewWindow && (
                                            <Tooltip title={`View ${title} (Opens in a new tab)`} placement="right">
                                                <CallMade />
                                            </Tooltip>
                                        )}
                                    </ListItem>
                                )}
                                {Boolean(isDivider) && (
                                    <Divider data-testid="main-menu-divider" classes={{ root: classes.divider }} />
                                )}
                            </Fragment>
                        );
                    })}
                </List>
            </Drawer>
            <PaneMenu
                classes={{ paper: classes.paneMenu }}
                data={submenu}
                selected={({ id }): boolean => id === Number(screenId)}
                open={subMenuExpanded}
                onClose={(): void => setSubMenuExpanded(false)}
                onMenuSelection={(item): void => handleMenuSelection(item)()}
            />
        </>
    );
});
